<template>
  <v-overlay absolute :value="overlay">
    <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
  </v-overlay>
</template>

<script>
import { OriginationApi } from '../api/application'
import { Tool, DataType } from '../js/core'
const tool = new Tool()
export default {
  data () {
    return {
      overlay: true,
      loanId: '',
      leadId: '',
      url: ''
    }
  },
  async created () {
    tool.clearItem()

    const _this = this
    const uuid = _this.$route.params.uuid
    await OriginationApi.getLoanIdDecrypt({ loanId: uuid }, function (result) {
      const params = result.split('#')

      _this.url = params[0]
      _this.leadId = params[1]
      _this.loanId = params[2]
      tool.setItem('AT', params[3])
      tool.setItem('LI', _this.loanId)
    })
    if (parseInt(_this.leadId) < 0) {
      await OriginationApi.loanMarketing({ loanId: _this.loanId, flag: _this.leadId },
        function (result) {
          _this.loanId = result
          tool.setItem('LI', result)
        }, function () {
          _this.loanId = undefined
        })
    } else {
      tool.setCookie('lId', _this.leadId)
    }

    tool.setCookie(DataType.COOKIE_KEY.LOAN_ID, _this.loanId)
    window.location.href = _this.url
  }
}
</script>
